var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mx-3 pt-1 transparent",
    staticStyle: {
      "max-width": "1000px"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-container', [_c('v-card', [_c('v-card-text', [_c('v-list', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {}, [_vm._v("Stages")]), _c('v-list-item-subtitle', [_vm._v("Stages of the sales pipeline.")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click() {
        _vm.$refs.ManageStages.open();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-view-list")]), _vm._v("Stages")], 1), _c('ManageStages', {
    ref: "ManageStages"
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }