<template>
  <div>
    <v-card class='mx-3 pt-1 transparent' elevation=0 style='max-width: 1000px;'>
      <v-container>

        <v-card>
          <v-card-text>
            <v-list>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Stages</v-list-item-title>
                  <v-list-item-subtitle>Stages of the sales pipeline.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='()=>{ $refs.ManageStages.open() }'><v-icon class='mr-2'>mdi-view-list</v-icon>Stages</v-btn>
                  <ManageStages ref='ManageStages' />
                </v-list-item-action>
              </v-list-item>

              <!--<v-divider></v-divider>-->


            </v-list>
          </v-card-text>
        </v-card>

      </v-container>
    </v-card>

  </div>
</template>

<style lang="scss">
</style>


<script>
import { mapActions } from 'vuex';
import ManageStages from '@/components/Dialogs/ManageStages'
export default {
  name: 'Stages',
  components: {
    ManageStages
  },
  watch:{
    $route: { handler(){ let t = `Stages`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE"]),
  },
  mounted(){
  }
}
</script>
