var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ListModal', {
    ref: "Modal",
    attrs: {
      "title": "Stages",
      "load-items": _vm.loadItems,
      "add-button": "",
      "item-key": "xid",
      "item-text": "title",
      "sortable": ""
    },
    on: {
      "add-item": _vm.addItem,
      "sort": _vm.sortItems
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text",
          attrs: {
            "title": "Edit Label"
          }
        }, [_vm._v("mdi-pencil")])], 1), _c('div', {
          staticClass: "float-left"
        }, [_c('v-menu', {
          staticClass: "float-left",
          attrs: {
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "title": "Menu Icon"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "color": item.menu_icon ? "primary" : "grey"
                }
              }, [_vm._v(_vm._s(item.menu_icon || "mdi-playlist-remove"))])], 1)];
            }
          }], null, true)
        }, [_c('v-autocomplete', {
          staticStyle: {
            "width": "400px"
          },
          attrs: {
            "solo": "",
            "hide-details": "",
            "autofocus": "",
            "clearable": "",
            "items": _vm.icons,
            "placeholder": "search for icon...",
            "value": item.menu_icon,
            "filter": function filter(item, q, t) {
              return q.toLowerCase().split(" ").every(function (q) {
                return t.includes(q);
              });
            }
          },
          on: {
            "input": function input(v) {
              return _vm.updateIcon(item, v);
            }
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('v-icon', {
                attrs: {
                  "color": "primary"
                }
              }, [_vm._v(_vm._s(item))]), _c('v-chip', {
                attrs: {
                  "label": ""
                }
              }, [_vm._v(_vm._s(item.substr(4).replaceAll("-", " ")))])];
            }
          }, {
            key: "item",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('v-icon', {
                staticClass: "primary--text mr-2"
              }, [_vm._v(_vm._s(item))]), _c('v-chip', {
                attrs: {
                  "label": ""
                }
              }, [_vm._v(_vm._s(item.substr(4).replaceAll("-", " ")))])];
            }
          }], null, true)
        })], 1)], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": "",
            "title": "Activate Taskflows"
          },
          on: {
            "click": function click($event) {
              return _vm.toggleTasksActive(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": item.activate_taskflows ? 'primary' : 'grey'
          }
        }, [_vm._v("mdi-source-merge")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": "",
            "title": "Delete Stage"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "error--text"
        }, [_vm._v("mdi-trash-can")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }