var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-3 pt-1 transparent",
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "elevation": "0",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-container', {
    staticClass: "px-0"
  }, [_c('div', {
    ref: "sortable"
  }, _vm._l(_vm.stages, function (stage) {
    return _c('v-card', {
      key: stage.xid,
      staticClass: "stage mb-4",
      attrs: {
        "sortable-id": stage.xid,
        "loading": _vm.itemsLoading.includes(stage.xid)
      }
    }, [_c('v-card-text', {
      staticClass: "pa-2 d-flex"
    }, [_c('v-icon', {
      staticClass: "mr-2 drag"
    }, [_vm._v("mdi-drag")]), _c('v-text-field', {
      staticClass: "mr-2 text-h5 titleInput",
      attrs: {
        "hide-details": "",
        "dense": "",
        "outlined": ""
      },
      on: {
        "input": function input($event) {
          return _vm.update(stage);
        }
      },
      model: {
        value: stage.label,
        callback: function callback($$v) {
          _vm.$set(stage, "label", $$v);
        },
        expression: "stage.label"
      }
    }), _c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "small": "",
              "fab": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.remove(stage);
        }
      }
    }, [_c('v-icon', {
      staticClass: "primary--text mr-4"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete")], 1)], 1)], 1)], 1)], 1);
  }), 1)]), _c('v-btn', {
    staticClass: "primary--text",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.addStage
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("Add State")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }