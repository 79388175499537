var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.show,
      "persistent": "",
      "max-width": "500px",
      "scrollable": ""
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text py-2"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm.addButton ? _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-item');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('v-card-text', {
    staticStyle: {
      "max-height": "500px",
      "height": "60vh"
    }
  }, [_c('v-list', {
    ref: "sortable",
    staticClass: "px-0",
    attrs: {
      "dense": _vm.dense
    }
  }, [_vm.selectable ? _c('v-list-item', {
    staticClass: "pr-0"
  }, [_c('v-spacer'), _c('v-btn', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": _vm.selectAll
    }
  }, [_vm._v("SELECT ALL")])], 1) : _vm._e(), _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item[_vm.itemKey],
      attrs: {
        "sortable-id": item[_vm.itemKey]
      }
    }, [_c('v-list-item', {
      staticClass: "px-0",
      attrs: {
        "sortable-id": item[_vm.itemKey]
      }
    }, [_vm.sortable ? _c('v-list-item-avatar', {
      staticClass: "dragMe ma-0",
      staticStyle: {
        "cursor": "grab"
      }
    }, [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v("mdi-drag")])], 1) : _vm._e(), _c('v-list-item-content', {}, [_c('v-list-item-title', [_vm._v(_vm._s(item[_vm.itemText]))])], 1), _vm.$scopedSlots.actions ? _c('v-list-item-action', {
      staticClass: "my-0"
    }, [_c('div', [_vm._t("actions", null, {
      "item": item
    })], 2)]) : _vm._e(), _vm.selectable ? [_c('v-list-item-action', {
      staticClass: "my-0 mr-4"
    }, [_c('v-checkbox', {
      staticClass: "pa-0",
      model: {
        value: _vm.selection[item[_vm.itemKey]],
        callback: function callback($$v) {
          _vm.$set(_vm.selection, item[_vm.itemKey], $$v);
        },
        expression: "selection[item[itemKey]]"
      }
    })], 1)] : _vm._e()], 2), _c('v-divider')], 1);
  })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.action ? [_c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.action))])] : _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("Done")])], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }