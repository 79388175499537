<template>
  <v-card class='mx-3 pt-1 transparent' elevation=0 style='max-width: 1200px;'
    :loading='loading' :disabled='loading'
  >
    <v-container class='px-0'>
      <div ref='sortable'>
        <v-card class='stage mb-4'
          v-for='stage in stages' :key='stage.xid' :sortable-id='stage.xid'
          :loading='itemsLoading.includes(stage.xid)'
        >
          <v-card-text class='pa-2 d-flex'>
            <v-icon class='mr-2 drag'>mdi-drag</v-icon>
            <v-text-field
              hide-details dense
              outlined
              class='mr-2 text-h5 titleInput'
              v-model='stage.label'
              @input='update(stage)'
            />
            <v-menu>
              <template v-slot:activator='{on,attrs}'>
                <v-btn v-on='on' v-bind='attrs' small fab text><v-icon>mdi-dots-horizontal</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item link @click='remove(stage)'><v-icon class='primary--text mr-4'>mdi-trash-can</v-icon>Delete</v-list-item>
              </v-list>
            </v-menu>

          </v-card-text>
        </v-card>
      </div>
    </v-container>
    <v-btn text class='primary--text' @click='addStage'><v-icon class='mr-2'>mdi-plus</v-icon>Add State</v-btn>
  </v-card>
</template>

<style lang="scss">
  .drag{ cursor: move; }
</style>


<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Sortable from 'sortablejs';
export default {
  name: 'stages',
  components: {
  },
  data(){ return {
    loading: false,
    itemsLoading: [],
    stages: [],
  }},
  watch: {
    $route: { handler(){ this.SET_TITLE('Billing States'); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "REFRESH_BILLING_STAGES"]),
    ...mapActions("API", ["API"]),

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortable, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.drag',
        onEnd: this.sort,
      });
    },

    sort(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let data = { sort_order: this.sortable.toArray() };
      this.loading = true;
      this.API({ method: 'POST', endpoint: `billing/stages/sort_order`, data })
      .then(this.REFRESH_BILLING_STAGES)
      .finally(() => { this.loading = false; });
    },

    refreshStages(){
      this.loading = true;
      this.API({ endpoint: 'billing/stages' })
      .then(stages => { this.stages = stages; })
      .finally(()=>{ this.loading = false; });
    },

    addStage(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'billing/stages/create' })
      .then(stage => { this.stages.push(stage); })
      .finally(() => { this.loading = false; });
    },

    update(stage){
      if(!this.itemsLoading.includes(stage.xid)) this.itemsLoading.push(stage.xid);
      let data = { ...stage };
      this.API({ method: 'PATCH', endpoint: `billing/stages/${stage.xid}`, data, autosave: 500 })
      .then(this.REFRESH_BILLING_STAGES)
      .finally(() => { this.itemsLoading = this.itemsLoading.filter(xid => xid != stage.xid); });
    },

    remove(stage){
      if(!this.itemsLoading.includes(stage.xid)) this.itemsLoading.push(stage.xid);
      this.API({ method: 'DELETE', endpoint: `billing/stages/${stage.xid}` })
      .then(()=>{
        this.REFRESH_BILLING_STAGES();
        this.stages = this.stages.filter(s => s.xid !== stage.xid);
      })
      .finally(() => { this.itemsLoading = this.itemsLoading.filter(xid => xid != stage.xid); });
    },

  },
  mounted(){
    this.makeSortable();
    this.refreshStages();
  }
}
</script>
