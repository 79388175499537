<template>
  <div>

    <ListModal
      ref='Modal' title='Stages'
      :load-items='loadItems' add-button @add-item='addItem'
      item-key='xid' item-text='title'
      sortable @sort='sortItems'
    >
      <template #actions='{item}'>

          <v-btn icon @click='editItem(item)' class='float-left'><v-icon class='primary--text' title="Edit Label">mdi-pencil</v-icon></v-btn>

          <div class="float-left">
            <v-menu :close-on-content-click='false' class="float-left">
              <template v-slot:activator='{on,attrs}'>
                <v-btn  icon v-on='on' v-bind='attrs' title="Menu Icon">
                  <v-icon  :color='item.menu_icon ? "primary" : "grey"'>{{item.menu_icon || "mdi-playlist-remove"}}</v-icon>
                </v-btn>
              </template>
                <v-autocomplete solo hide-details autofocus clearable
                  style='width:400px;'
                  :items='icons'
                  placeholder='search for icon...'
                  :value='item.menu_icon'
                  @input='v=>updateIcon(item,v)'
                  :filter='(item, q, t)=>q.toLowerCase().split(" ").every(q=>t.includes(q))'
                >
                  <template #selection='{item}'>
                    <v-icon color='primary'>{{item}}</v-icon>
                    <v-chip label>{{item.substr(4).replaceAll("-", " ")}}</v-chip>
                  </template>
                  <template #item='{item}'>
                    <v-icon class='primary--text mr-2'>{{item}}</v-icon>
                    <v-chip label>{{item.substr(4).replaceAll("-", " ")}}</v-chip>
                  </template>
                </v-autocomplete>
            </v-menu>
          </div>

          <v-btn icon @click='toggleTasksActive(item)' class='float-left' title="Activate Taskflows"><v-icon :color="item.activate_taskflows?'primary':'grey'">mdi-source-merge</v-icon></v-btn>

          <v-btn icon @click='deleteItem(item)' class='float-left' title="Delete Stage"><v-icon class='error--text'>mdi-trash-can</v-icon></v-btn>

      </template>
    </ListModal>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
//import Deferred from '@/helpers/Deferred'
import ListModal from '@/components/Common/ListModal'
import { Icons } from '@/helpers/icons.js';
export default {
  name: 'TaskList',
  components: {
    ListModal,
  },

  props: {},

  data(){
    return {}
  },

  computed: {
    icons: () => Icons,
  },

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['CONFIRM', 'PROMPT', 'REFRESH_STAGES']),

    open(){
      this.$refs.Modal.open();
    },

    loadItems(){
      return this.API({ method: 'GET', endpoint: 'stages' });
    },

    async addItem(){
      let title = await this.PROMPT({ title: 'New Stage', message: 'Name of the new stage:' });
      let rq = this.API({
        method: 'POST',
        endpoint: 'stages/create',
        data: { title }
      });
      this.$refs.Modal.load(rq, true);
    },

    async editItem(item){
      let title = await this.PROMPT({ title: 'Rename Stage', message: 'New name:', placeholder: item.title });
      if(title === item.title) return;
      let rq = this.API({
        method: 'PATCH',
        endpoint: `stages/${item.xid}`,
        data: { title }
      });
      this.$refs.Modal.load(rq, true);
    },

    async deleteItem(item){
      await this.CONFIRM({ title: 'Delete Task Type', message: `Delete "${item.title}"?` });
      let rq = this.API({
        method: 'DELETE',
        endpoint: `stages/${item.xid}`
      });
      this.$refs.Modal.load(rq, true);
    },

    sortItems(sort_order){
      let rq = this.API({
        method: 'POST',
        endpoint: 'stages/sort_order',
        data: { sort_order }
      });
      this.$refs.Modal.load(rq, true);
    },

    updateIcon(item, menu_icon){
      menu_icon = menu_icon ?? "";
      if(menu_icon === item.menu_icon) return;
      let rq = this.API({
        method: 'PATCH',
        endpoint: `stages/${item.xid}`,
        data: { menu_icon }
      });
      this.$refs.Modal.load(rq, true);
    },

    toggleTasksActive(item){
      let rq = this.API({
        method: 'PATCH',
        endpoint: `stages/${item.xid}`,
        data: { activate_taskflows: !item.activate_taskflows }
      });
      this.$refs.Modal.load(rq, true);
    }

  },
}
</script>
