<template>

    <v-dialog :value='show' persistent  max-width='500px' scrollable>
      <v-card :disabled='loading' :loading='loading'>
        <v-card-title class='primary white--text py-2'>
          {{title}}
          <v-spacer></v-spacer>
          <v-btn fab small text dark v-if='addButton' class='' @click="$emit('add-item')"><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
        <v-card-text style='max-height:500px; height: 60vh;' class=''>
          <v-list class='px-0' ref='sortable' :dense="dense">
            <v-list-item class="pr-0" v-if="selectable">
              <v-spacer />
              <v-btn text x-small style="float:right" @click="selectAll">SELECT ALL</v-btn>
            </v-list-item>
            <div v-for='item in items' :key='item[itemKey]' :sortable-id='item[itemKey]'>
              <v-list-item class='px-0'  :sortable-id='item[itemKey]'>
                <v-list-item-avatar v-if='sortable' class='dragMe ma-0' style='cursor:grab'>
                  <v-icon class='mr-2'>mdi-drag</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class=''>
                  <v-list-item-title>{{item[itemText]}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class='my-0' v-if='$scopedSlots.actions'>
                  <div><slot name='actions' :item='item' /></div>
                </v-list-item-action>

                <template v-if="selectable">
                  <v-list-item-action class="my-0 mr-4">
                    <v-checkbox v-model="selection[item[itemKey]]" class="pa-0"></v-checkbox>
                  </v-list-item-action>
               </template>

              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if="action">
            <v-btn class='secondary' @click='show=false'>Cancel</v-btn>
            <v-btn class='primary' @click='confirm'>{{ action }}</v-btn>
          </template>
          <v-btn v-else class='primary' @click='show=false'>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
import Sortable from 'sortablejs'
export default {
  name: 'ListModal',
  components: {},
  props: {
    title: { type: String, required: true },
    addButton: { type: Boolean, required: false, default: false },
    loadItems: { type: Function, required: true },
    itemKey: { type: String, default: 'id' },
    itemText: { type: String, default: 'text' },
    sortable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    action: { type: String, default: null },
  },
  data(){
    return {
      loading: false,
      show: false,
      items: [],
      sortable_: null,
      selection: {},
    }
  },
  computed: {
    selected(){
      return Object.keys(this.selection)
        .filter(key => this.selection[key]);
    }
  },
  methods: {
    refresh(){
      this.loading = true;
      this.loadItems()
      .then(items => {
        this.items = items;
        this.selection = Object.fromEntries(items.map(i => [i.xid, false] ));
      })
      .finally(() => { this.loading = false; });
    },

    open(){
      this.items = [];
      this.show = true;
      this.refresh();
      this.$nextTick(this.makeSortable);
    },

    close(){
      this.show = false;
    },

    makeSortable(){
      if(this.sortable_) return; //already initialised
      this.sortable_ = new Sortable(this.$refs.sortable.$el, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.dragMe',
        onEnd: this.sorted,
      });
    },

    sorted(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      this.$emit('sort', this.sortable_.toArray());
    },

    load(promise, refresh){
      this.loading = true;
      promise.then(() => {
        if(refresh) this.refresh();
      })
      .finally(() => { this.loading = false; });
    },

    selectAll(){
      let select = this.selected.length != this.items.length;
      Object.keys(this.selection).forEach(k => {
        this.selection[k] = select;
      });
    },

    confirm(){
      this.$emit('action');
      this.close();
    }

  },
  created(){
  },
  mounted(){
  }
}
</script>
